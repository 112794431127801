.page {

    width: 210mm;
    height: 297mm;
    padding: 12mm;
    margin: 10mm auto;
    border-radius: 5px;
    background-image: url('./../images/watermark.jpg');
    background-size: 100% 100%;
    background-position: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    display: block;

}

.pageView{
    width: 210mm;
    height: 297mm;
    padding: 12mm;
    margin: 10mm auto;
    border-radius: 5px;
    background-image: url('./../images/watermark001.png');
    background-size: 100% 100%;
    background-position: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    display: block;

}

.page_demo {

    width: 210mm;
    height: 297mm;
    padding: 12mm;
    margin: 10mm auto;
    border-radius: 5px;
    background-image: url('./../images/demo.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
    display: block;

}

.page[size="A4"] {
    width: 21cm;
    height: 29.7cm;
    orientation:portrait;
}

.page[size="A4"][layout="portrait"] {
    width: 29.7cm;
    height: 21cm;

}


.page[size="A5"] {
    width: 14.8cm;
    height: 21cm;
    orientation:portrait;
}

.page[size="A5"][layout="portrait"] {
    width: 21cm;
    height: 14.8cm;
}

@page{
    size: A4,A5 portrait;
}

.header,
.footer {
    position: absolute;
    left: 0;
    right: 0;   
    padding-right: 1.5cm;
    padding-left: 1.5cm;
   
}



.header {
    top: 0;
    padding-top: 5mm;
    padding-bottom: 3mm;
}

.footer {
    bottom: 0;
    color: #000;
    padding-top: 6mm;
    padding-bottom: 5mm;
    margin-bottom: 0px;
}

@media print {
 
   
    body,
    html {
        width: 21cm !important;
        height: 29.7cm !important;
        margin: 0;
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background-image: url('./../images/watermark.jpg');
        page-break-after: always;
    }

    .print-preview {
        width: 21cm;
        height: 29.7cm;
        margin: 0;
        border: 5px solid #000;
    }

    .header,
    .footer {
        position: fixed;
        left: 0;
        right: 0;
     
        padding-right: 1.5cm;
        padding-left: 1.5cm;
    }
}

.table {
    border: dotted;
    vertical-align: center !important;
}

.tableTable {
    border: none
}

.td {
    border: #000 !important
}


.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
    height: 50px;
 }

 .textRemark {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
   
 }